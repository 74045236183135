import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import super_sucker from "../../public/static/images/products/super_sucker.jpg"

function SuperSucker() {

  const prodDetail = {
    "name": "SUPER SUCKER",
    "detail": {
      "about" : ["The Inventa Energy Pvt. Ltd. Super Sucker Machine is designed with the latest technology and Manufactured with strictest adherence to the highest.", "International standard as well as Indian standard. These Super Sucker Machines are used for de-silting / de-checking sewer lines and are capable of performing the all function."],
      "advantages": [
        "Equipment filled with a high flow continuous rating vacuum blower.",
        "This machine can carry out both the operations of suction as well as jetting.",
        "The operation is the same as that of individual high pressure jetting machine and individual suction machine with blow operation i.e. of Muljet and Gully Man."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Tank Size",
        "Sheet Material",
        "Thickness",
        "Blower",
        "Jetting Pump",
        "Hoses",
        "Drive",
        "Gear",
        "Power Take Off",
        "Hydraulic Pump",
        "Suitability"
      ],
      "column2": [
        "IEHF / IERSSJM",
        "16 Ton, 25 Ton Etc.",
        "5000-20000 liters or Customized tanks",
        "Mild Steel (IS:2062), SS-304 / CRCA Sheet/ Eq.",
        "6 mm, 8 mm or as per requirement",
        "Make –Kay International / Swam / Eq. (4600 m3/hr or Required Capacity)",
        "Make –Wasp / Pressure Jet/ Eq.",
        "( 290 LPM @ 180 Bar or Required Capacity) Suction Hose ( Upto 150 NB & 30 Mtrs)",
        "Suitable Power Transfer from Vehicle PTO. or an Auxiliary Engine",
        "VAS / Eq. With upto 400 kgm capacity",
        "Vas/eq. With Upto 400 kgm Capacity Suitable Power Transfer Ratio to Drive Pumps",
        "2 nos. each for Tipping & Door opening 40-80 LPM",
        "Required Capacity) Municipalities, Building Developments as well as Heavy Cleaning Operations as required."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={super_sucker} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default SuperSucker
